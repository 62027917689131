import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "statistics-logging"
    }}>{`Statistics Logging`}</h1>
    <p>{`If you hosted an event that wasn't created within the Events Platform, you can use Log Statistics page within the left sidebar to log attendance details. This ensures the relevant data is recorded and viewable within the Statistics page. These statistics logged most commonly relate to events that take place outside of libraries that would otherwise be unaccounted for within the platform.`}</p>
    <p>{`There is no need to log statistics for events that exist within Events Platform, as they're either automated based on bookings, or able to be added directly to each event's page once the event concludes.`}</p>
    <h2 {...{
      "id": "logging-statistics-for-staff"
    }}>{`Logging Statistics for Staff`}</h2>
    <ol>
      <li parentName="ol">{`While logged in as a staff member, click the Log Statistics menu item in the left sidebar`}</li>
      <li parentName="ol">{`Once you are on the page, complete the Event Details form to select which location the event took place at or which team was the host. You can also provide the number attendees along with a note.`}</li>
      <li parentName="ol">{`When you're ready, click Log Statistic to submit the data`}</li>
    </ol>
    <Alert kind="info" mdxType="Alert">Recently logged statistics are shown in a table below the form. Use this to ensure statistics are not accidentally added multiple times.</Alert>
    <h2 {...{
      "id": "viewing-logged-statistics"
    }}>{`Viewing Logged Statistics`}</h2>
    <p>{`The statistics that are logged form part of the platform statistics package, accessible for Managers and Administrators by clicking Stats within the left sidebar. When the statistics logged are associated with a location, they are viewable within the location's section by clicking the blue Uploaded Stats button.`}</p>
    <p>{`When statistics are associated with a team instead of a location, they are viewable below the Category Overview card (that includes the pie chart) in a card titled Outreach Overview.`}</p>
    <p>{`In both cases, statistics are grouped by event type.`}</p>
    <h2 {...{
      "id": "customising-available-teams--event-types"
    }}>{`Customising Available Teams & Event Types`}</h2>
    <p>{`Administrators have the ability to edit the team names and event types displayed, this is possible within the Advanced menu item. Please note that when team names and event types are modified, statistics that have been previously uploaded are unaffected.`}</p>
    <ol>
      <li parentName="ol">{`While logged in as an Administrator, click the Advanced menu item in the left sidebar`}</li>
      <li parentName="ol">{`Click the blue Manage Statistics Logging button`}</li>
      <li parentName="ol">{`From this page you can edit any of the fields, use the blue Save button to apply changes`}</li>
      <li parentName="ol">{`You can also add new event types and team names using the blue + icon displayed within the heading of each section`}</li>
    </ol>
    <Alert kind="info" mdxType="Alert">The ability to modify team names and event types is available only to users logged in as Administrators</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      